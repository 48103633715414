import React from "react";

import { notEmpty, SiteLink } from "@parataxic/shared-ui";

import { PopoverSiteLink } from "./Popover";

import "./Component.css";

interface ITheoryLinkList {
  theories:
    | GatsbyTypes.Maybe<readonly GatsbyTypes.Maybe<GatsbyTypes.MdxTheory>[]>
    | null
    | undefined;
}
export const TheoryLinkList: React.FC<
  ITheoryLinkList & { linkProps?: React.ComponentProps<typeof PopoverSiteLink> }
> = ({ theories, linkProps }) => {
  if (!theories) {
    return null;
  }
  return (
    <>
      {theories.filter(notEmpty).map((theory, idx) => [
        idx > 0 && ", ",
        <PopoverSiteLink key={idx} href={theory.slug} {...linkProps}>
          {theory.title}
        </PopoverSiteLink>,
      ])}
    </>
  );
};

interface ISynonymLinkList {
  synonyms:
    | GatsbyTypes.Maybe<
        readonly GatsbyTypes.Maybe<GatsbyTypes.MdxTermSynonyms>[]
      >
    | null
    | undefined;
}
const SynonymLinkList: React.FC<ISynonymLinkList> = ({ synonyms }) => {
  if (!synonyms) {
    return null;
  }
  return (
    <>
      {synonyms
        .filter(notEmpty)
        .map((synonym: GatsbyTypes.TermSynonym, idx) => [
          idx > 0 && ", ",
          <React.Fragment key={idx}>{synonym.title}</React.Fragment>,
        ])}
    </>
  );
};

const Term: React.FC<{
  term: GatsbyTypes.MdxTerm;
  urlSearchParams: string;
}> = ({ term, urlSearchParams = "" }) => {
  if (!term) {
    return null;
  }
  return (
    <React.Fragment>
      {" "}
      <li>
        {term.showPage ? (
          <SiteLink href={`${term.slug}${urlSearchParams}`}>
            {term.title}
          </SiteLink>
        ) : (
          term.title
        )}
        {term.synonyms && (
          <>
            {term.synonyms && (
              <em className="term__synonyms">
                {" "}
                aka <SynonymLinkList synonyms={term.synonyms} />
              </em>
            )}
          </>
        )}

        {term.compareTo?.length > 0 && (
          <>
            <br />
            <em className="term__compareTo">
              compare to{" "}
              {term.compareTo &&
                term.compareTo.filter(notEmpty).map((c2, idx2) => [
                  idx2 > 0 && ", ",
                  <React.Fragment key={idx2}>
                    {c2.showPage && c2.slug ? (
                      <SiteLink href={c2.slug}>{c2.title}</SiteLink>
                    ) : (
                      c2.title
                    )}{" "}
                    in <TheoryLinkList theories={c2.theories} />
                  </React.Fragment>,
                ])}
            </em>
          </>
        )}
        {term.belongsTo?.length > 0 && (
          <em className="term__belongsTo">
            {(term.synonyms?.length > 0 || term.compareTo?.length > 0) && ", "}{" "}
            a type of{" "}
            {term.belongsTo &&
              term.belongsTo
                .filter(notEmpty)
                .map((c2, idx2) => [
                  idx2 > 0 && ", ",
                  <React.Fragment key={idx2}>{c2.title}</React.Fragment>,
                ])}
          </em>
        )}
      </li>
    </React.Fragment>
  );
};

const nestedSort =
  (prop1: string, prop2?: string, direction = "asc") =>
  (e1: Record<string, unknown>, e2: Record<string, unknown>) => {
    const a: unknown = prop2 ? e1[prop1][prop2] : e1[prop1],
      b: unknown = prop2 ? e2[prop1][prop2] : e2[prop1],
      sortOrder = direction === "asc" ? 1 : -1;
    return a < b ? -sortOrder : a > b ? sortOrder : 0;
  };

interface ITermList {
  terms: GatsbyTypes.MdxTerm[];
  // terms: readonly GatsbyTypes.Maybe<GatsbyTypes.Term>[];
  urlSearchParams: string;
}
export const TermList: React.FC<ITermList> = ({
  terms,
  urlSearchParams = "",
  ...props
}) => {
  if (!terms) {
    return <></>;
  }
  return (
    <div className="term-list">
      <ul style={{ listStyle: "disc" }} {...props}>
        {terms
          .sort(nestedSort("belongsTo"))
          .filter(notEmpty)
          .map((term: GatsbyTypes.MdxTerm, idx: number) => (
            <Term key={idx} term={term} urlSearchParams={urlSearchParams} />
          ))}
      </ul>
    </div>
  );
};
