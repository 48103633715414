// This dependency is used in gatsby-remark-autolink-headers to generate the slugs
import React from "react";

import { MDXRenderer } from "gatsby-plugin-mdx";

import {
  GridWithSidebarStyling,
  LinkList,
  notEmpty,
  ToCSidebar,
  useToC,
} from "@parataxic/shared-ui";

import { BookBox } from "../components/Book";
import { ParataxicMDXProvider } from "../components/MDXProvider";
import { PDFBox } from "../components/PDF";
import { PopoverSiteLink } from "../components/Popover";
import { TermList } from "../components/Term";
import {
  extractTitlesFromTheory,
  TheoryCreatorList,
} from "../components/Theory";
import { VideoBox } from "../components/Video";

export interface PersonalityTheoryViewProps {
  pageContext: {
    readonly theory: GatsbyTypes.MdxTheory;
    readonly mdx?: GatsbyTypes.SitePageContextMdx;
  };
}

const View: React.FC<PersonalityTheoryViewProps> = ({
  pageContext: { mdx, theory },
  children,
}) => {
  const { books, videos, pdfs, terms } = theory;
  const { defaultTitle, isNameInTheory, pageTitle } =
    extractTitlesFromTheory(theory);
  const MDXTermList = () => (
    <TermList
      terms={terms as GatsbyTypes.MdxTerm[]}
      urlSearchParams={`?referrer=${theory.slug}#${theory.id}`}
    />
  );

  useToC({ querySelector: "#content nav div ul li a" });
  const MDXComponents = {
    TermList: MDXTermList,
  };

  const noTermsInMdx =
    // eslint-disable-next-line
    !mdx?.tableOfContents?.items
      ?.filter(notEmpty)
      .map?.(({ title = "" }: { title: string }) => title)
      .includes("Terms") ?? true;
  return (
    <div
      style={{ paddingX: "1rem", ...GridWithSidebarStyling }}
      id="content"
      role="article"
    >
      <ToCSidebar mdx={mdx} variant="grid-with-sidebar">
        <VideoBox videos={videos} />
        <BookBox books={books} />
        <PDFBox pdfs={pdfs} />
      </ToCSidebar>

      <div>
        <TheoryCreatorList persons={theory.authors} style={{ marginTop: 0 }} />
        <h1>
          {theory.namedTitle || (isNameInTheory ? defaultTitle : theory.title)}
        </h1>

        <div>
          {theory?.contributors && (
            <div>
              <strong style={{ marginRight: ".25rem" }}>Contributors</strong>
              <LinkList
                links={theory.contributors}
                Component={PopoverSiteLink}
              />
            </div>
          )}
        </div>
        <hr style={{ color: "var(--color-gray-300)" }} />

        <div className="markdown">
          {mdx?.body ? (
            <ParataxicMDXProvider components={MDXComponents}>
              <MDXRenderer>{mdx?.body}</MDXRenderer>
            </ParataxicMDXProvider>
          ) : (
            <div
              style={{
                paddingTop: "1rem",
                fontStyle: "italic",
                fontSize: ".8rem",
                color: "rgba(0, 0, 0, .7)",
              }}
            >
              This is {`${!mdx?.body ? "a placeholder for " : ""}`} {pageTitle}.
              {(theory.authors || theory.contributors) && (
                <>
                  {" "}
                  For related theorists see{" "}
                  <TheoryCreatorList
                    persons={theory.authors}
                    possessiveNoun={false}
                    display="inline-flex"
                  />
                  {theory.authors && theory.contributors && ", "}
                  <LinkList
                    links={theory.contributors}
                    Component={PopoverSiteLink}
                  />
                  .
                </>
              )}
            </div>
          )}

          {children}
          {mdx && noTermsInMdx && terms && (
            <div className="markdown">
              <h2 className="heading">Terms</h2> <TermList terms={terms} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default View;
