import React from "react";

import { LinkBox } from "@parataxic/shared-ui";

import "./Component.css";

export const PDFBoxItem: React.FC<
  {
    readonly pdf: GatsbyTypes.Maybe<GatsbyTypes.MdxPdf>;
  } & React.ComponentProps<typeof LinkBox>
> = ({ pdf, ...rest }) => {
  if (!pdf) {
    return null;
  }
  return (
    <LinkBox href={pdf.slug} boxProps={rest}>
      {pdf.title}
    </LinkBox>
  );
};

export const PDFList: React.FC<{
  pdfs: readonly GatsbyTypes.Maybe<GatsbyTypes.MdxPdf>[];
}> = ({ pdfs }) => {
  if (!pdfs) {
    return null;
  }

  return (
    <div role="list">
      {pdfs.map((pdf, idx) => (
        <PDFBoxItem pdf={pdf} key={idx} role="listitem" />
      ))}
    </div>
  );
};

export const PDFBox: React.FC<{
  pdfs: readonly GatsbyTypes.Maybe<GatsbyTypes.PDFMdx>[];
  title?: string;
}> = ({ pdfs, title }) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const onToggle = React.useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen, setIsOpen]);
  if (!pdfs?.length) {
    return null;
  }

  return (
    <div className="pdf-box">
      <h5 className="pdx-box__header">
        {title ?? "PDFs"} ({pdfs.length})
        <span onClick={onToggle} className="pdf-box__header__text">
          {isOpen ? "[-] close" : "[+] open"}
        </span>
      </h5>{" "}
      {isOpen && <PDFList pdfs={pdfs} />}
    </div>
  );
};
