import React from "react";

import { LinkBox } from "@parataxic/shared-ui";

import "./Component.css";

interface IVideoItem extends React.ComponentProps<typeof LinkBox> {
  readonly video: GatsbyTypes.Maybe<GatsbyTypes.MdxVideo>;
}

export const VideoItem: React.FC<IVideoItem> = ({ video, ...rest }) => {
  if (!video) {
    return null;
  }
  return (
    <LinkBox
      href={video.slug}
      boxProps={{
        style: {
          width: "100%",
          position: "relative",
          display: "flex",
          justifyContent: "flex-end",
          ...rest,
        },
      }}
    >
      {video.title}
      {video.duration && (
        <span
          style={{
            fontSize: "0.7rem",
            color: "var(--color-gray-500)",
            lineHeight: "normal",
            margin: ".15rem",
            marginLeft: "0.3rem",
            paddingBottom: ".1rem",
            paddingLeft: ".1rem",
            paddingRight: ".1rem",
          }}
        >
          &nbsp; {video.duration}
        </span>
      )}
    </LinkBox>
  );
};

interface IVideoList {
  videos: readonly GatsbyTypes.Maybe<GatsbyTypes.MdxVideo>[];
}

export const VideoList: React.FC<IVideoList> = ({ videos }) => {
  if (!videos) {
    return null;
  }

  return (
    <div role="list">
      {videos.map((video, idx) => (
        <VideoItem video={video} key={idx} role="listitem" />
      ))}
    </div>
  );
};

export const VideoBox: React.FC<IVideoList & { title?: string }> = ({
  videos,
  title,
}) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const onToggle = React.useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen, setIsOpen]);
  if (!videos?.length) {
    return null;
  }

  return (
    <div>
      <h5>
        {title ?? "Videos"} ({videos.length})
        <span onClick={onToggle} className="video-box__header__text">
          {isOpen ? "[-] close" : "[+] open"}
        </span>
      </h5>{" "}
      {isOpen && <VideoList videos={videos} />}
    </div>
  );
};
